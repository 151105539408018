import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const LINKEDIN_LINK = 'https://www.linkedin.com/in/mbrauninger/';
const YOUTUBE_LINK = 'https://www.youtube.com/@MikeBrauninger';
const GITHUB_LINK = 'https://github.com/mbrauninger';

const useStyles = makeStyles((theme) => ({
    footer: {
        width: '100%',
        height: 125,
        backgroundColor: '#3b3b3b',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 80,
        marginTop: 'auto',
        [theme.breakpoints.down('md')]: {
            gap: 40,
        },
        position: 'relative',
    },
    linkedin: {
        '&:hover': {
            filter: 'grayscale(100%) opacity(70%)',
        },
        cursor: 'pointer',
        height: 50,
        width: 50,
        position: 'relative',
        bottom: 15,
    },
    youtube: {
        '&:hover': {
            filter: 'grayscale(100%) opacity(70%)',
        },
        cursor: 'pointer',
        height: 67,
        width: 67,
        position: 'relative',
        bottom: 15,
    },
    github: {
        '&:hover': {
            filter: 'grayscale(100%) opacity(70%)',
        },
        cursor: 'pointer',
        height: 60,
        width: 60,
        position: 'relative',
        bottom: 15,
    },
    copyright: {
        color: 'white',
        position: 'absolute',
        top: 85,
    },
}));

export function Footer() {
    function handleLogoClick(url: string) {
        window.open(url, '_blank');
    }
    const classes = useStyles();
    return (
        <Box className={classes.footer}>
            <Typography className={classes.copyright}>© 2025 Mike Brauninger. All rights reserved.</Typography>
            <Box
                component="img"
                className={classes.linkedin}
                onClick={() => handleLogoClick(LINKEDIN_LINK)}
                alt="Logo"
                src={`${process.env.PUBLIC_URL}/linkedin-app-white-icon.png`}
            />
            {/* <Box
                component="img"
                className={classes.youtube}
                onClick={() => handleLogoClick(YOUTUBE_LINK)}
                alt="Logo"
                src={`${process.env.PUBLIC_URL}/youtube-icon-white.png`}
            /> */}
            <Box
                component="img"
                className={classes.github}
                onClick={() => handleLogoClick(GITHUB_LINK)}
                alt="Logo"
                src={`${process.env.PUBLIC_URL}/github-icon.png`}
            />
        </Box>
    );
}
